<template>
    <div id="content" class="library-category-management">
        <div class="bar-actions">
            <TranslateButton :entityId="'library_category.' + libraryCategoryRender && libraryCategoryRender.id ? libraryCategoryRender.id : ''" :translationLangs="libraryCategoryRender.translations" @newTranslation="newTranslation" @deleteTranslation="deleteTranslation"></TranslateButton>
            <Button bType="cancel" :bCallback="goBack" bLabel="user.form.cancel"></Button>
            <Button bType="save" :bCallback="saveLibraryCategory" customClass="btn-action" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
            <Button v-if="$route.params.id" bType="more" :bCallback="showMoreOptions"></Button>
            <div class="more-options" v-if="moreOptions">
                <Button bType="delete" :bCallback="showDeleteResourceFolderModal" bLabel="menu_aux.action.delete" customClass="btn-secondary"></Button>
            </div>
        </div>

        <div class="form-container" v-if="libraryCategoryRender">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('library.categories.name') }}</h2>
                        <div class="user-answer">
                            <TranslateInput :translation_keyname="'library_category.' + libraryCategoryRender.id + '.name'" @input="updateValues($event)" v-model="libraryCategoryRender.name" :gOptions="{ mandatory: true }" :_placeholder="$t('library.form.name_placeholder')" :gLabel="$t('library.categories.name')" :gMessage="{ error: $t('library.form.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></TranslateInput>
                            <!-- <Input @input="updateValues($event)" v-model="libraryCategoryRender.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('library.form.name_placeholder')" :gLabel="$t('library.categories.name')" :gMessage="{ error: $t('library.form.error_name') }" :gState="errors.name ? 'error' : ''" gSize="m"></Input> -->
                        </div>
                    </div>
                    <div class="right-container translate-hide">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="libraryCategoryRender.status ? $t('sites.form.active') : $t('sites.form.inactive')"></h2>
                            <div class="switch-click" @click="popupStatus()">
                                <input class="switch-input-form" type="checkbox" v-model="libraryCategoryRender.status" disabled="true" />
                            </div>
                        </div>
                        <p class="description" v-html="$t('library.form.inactive_descrip')"></p>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container translate-hide">
                        <!-- SWITCH HERE PLS -->
                        <div class="switch">
                            <h2 class="label-form" v-html="$t('library.categories.limit_role')"></h2>
                            <input class="switch-input-form" @change="validateRoles()" type="checkbox" v-model="roleSwitch" @click="hideError('role')" />
                        </div>
                        <p v-if="!roleSwitch" class="description" v-html="$t('library.categories.limit_role_desc')"></p>
                        <div class="answer">
                            <h2 class="label-form" v-if="roleSwitch">
                                {{ $t('library.categories.access_roles') }}
                            </h2>
                            <p v-if="errors.roles" class="error-form error-field" v-html="$t('library.form.error_roles')"></p>
                            <p v-if="roleSwitch" class="description" v-html="$t('library.categories.roles_desc')"></p>
                            <div v-if="roleSwitch && Object.values(optionsRoles).length > 0" class="user-answer select tag">
                                <Input :refreshComponent="roleSwitch" v-if="libraryCategoryRender.roles && roleSwitch && Object.values(optionsRoles).length > 0" :autocompleteValue="Object.values(libraryCategoryRender.roles)" @change="updateRoles" :gLabel="$t('user.form.where_access')" gType="autocomplete" v-model="libraryCategoryRender.roles" :gOptions="{ multiple: true, newInput: true, returnObject: true, itemText: 'name', itemValue: 'id' }" :gItems="Object.values(optionsRoles)" :placeholder="$t('library.categories.select_role')" gSize="m"></Input>
                            </div>
                        </div>
                    </div>
                    <div class="right-container"></div>
                </div>
                <div class="label-container full">
                    <div class="user-answer">
                        <h2 class="label-form" style="margin-right: 6px" v-html="$t('library.categories.is_parent')"></h2>
                        <input class="switch-input-form" type="checkbox" v-model="parentCategory" @change="validateParent()" @click="hideError('parent_id')" />
                    </div>
                    <div class="user-answer select translate-disable w100" v-if="!parentCategory">
                        <Input @change="libraryCategoryRender = libraryCategoryRender" v-if="optionsCategories" :gOptions="{ returnObject: false }" :gLabel="$t('library.categories.parent_desc')" :gState="errors.parent_id ? 'error' : ''" :gMessage="{ error: $t('library.categories.error_parent_id') }" gType="select" v-model="libraryCategoryRender.parent_id" :gItems="optionsCategories" gSize="sm"></Input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'

export default {
    name: 'LibraryCategoryManagement',
    props: { id: undefined },
    components: { Tags },
    data() {
        return {
            refreshInput: 0,
            roleSwitch: false,
            parentCategory: true,
            isParent: false,
            idCategory: false,
            sendForm: true,
            errors: {
                name: false,
                status: false,
                roles: false,
                parent_id: false
            },
            color: '',
            form: false,
            colors: extraColors,
            moreOptions: false,
            roleSelected: [],
            optionsCategories: []
        }
    },
    computed: {
        optionsRoles() {
            return this.$store.getters['library/getRolesCategoryFilters']()
        },
        categoriesRecursive() {
            return this.$store.getters['library/getCategoriesRecursive']
        },
        libraryCategoryRender: {
            get() {
                if (this.idCategory) {
                    var category = this.$store.getters['library/getLibraryCategories'](this.idCategory)
                    if (!category.parent_id) {
                        this.parentCategory = true
                    } else {
                        this.parentCategory = false
                    }
                    if (category.status == -2) {
                        category.status = 0
                    }

                    if (Object.keys(category.roles).length > 0) {
                        this.roleSwitch = true
                    }
                    return category
                }
            },
            set(value) {
                this.$store.commit('library/updateLibraryCategory', value)
            }
        }
    },
    methods: {
        // Define la función recursiva para generar los breadcrumbs
        parseCategories(categories, parentCategory = null) {
            const result = []
            for (const categoryId in categories) {
                const category = categories[categoryId]
                // if the category is the same as the current category, skip it to avoid infinite recursion
                if (category.id == this.id) {
                    continue
                }
                const categoryName = category.name
                const categoryIdString = category.id
                const categoryFullName = parentCategory ? `${parentCategory} > ${categoryName}` : categoryName
                result.push({ name: categoryFullName, id: categoryIdString })
                if (category.subcategories) {
                    const subcategories = this.parseCategories(category.subcategories, categoryFullName)
                    result.push(...subcategories)
                }
            }
            return result
        },
        newTranslation(code) {
            this.$set(this.libraryCategoryRender.translations, code, { missing: 0, translated: 0 })
        },

        deleteTranslation(code) {
            this.$delete(this.libraryCategoryRender.translations, code)
        },
        showMoreOptions() {
            // METHOD TO SHOW OR HIDE MORE OPTION BUTTONS
            this.moreOptions = !this.moreOptions
        },
        updateRoles(value) {
            this.$store.commit('library/updateLibraryCategoryRoles', { id: this.idCategory, roles: value })
        },
        updateValues(v) {
            this.libraryCategoryRender = this.libraryCategoryRender
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },
        validateParent() {
            if (!this.parentCategory) {
                this.libraryCategoryRender.color = ''
                this.libraryCategoryRender.parent_id = true
                this.updateValues()
            } else {
                this.libraryCategoryRender.parent_id = false
                this.updateValues()
            }
        },
        validateRoles() {
            if (!this.roleSwitch) {
                this.libraryCategoryRender.roles = []
                this.updateValues()
            }
        },
        hideError(error) {
            if (this.roleSwitch == true && error == 'role') {
                this.errors.roles = false
            }
            if (this.parentCategory == false && error == 'parent_id') {
                this.errors.parent_id = false
            }
        },
        setColor(color) {
            this.libraryCategoryRender.color = color
            this.libraryCategoryRender.parent_id = false
            this.updateValues()
        },

        popupStatus() {
            var self = this
            if (self.libraryCategoryRender.status == '1' || self.libraryCategoryRender.status == true) {
                self.$popup.confirm({
                    message: self.$t('library.form.popup_inactive'),
                    textSave: self.$t('library.form.popup_inactive_confirm'),
                    callSave: function () {
                        self.libraryCategoryRender.status = false
                        self.updateValues()
                    },
                    callCancel: function () {
                        self.libraryCategoryRender.status = true
                        self.updateValues()
                    }
                })
            } else {
                self.libraryCategoryRender.status = true
                self.updateValues()
            }
        },

        load() {
            var self = this
            // Cargar categorías cuando se hace refresh en el formulario
            if (Object.keys(self.$store.getters['library/getCategoriesForm']).length == 0) {
                self.$store.dispatch('library/loadLibraryCategories', {}).then(() => {
                    self.$store.dispatch('library/loadLibraryCategoriesForm', { status: 1 }).then(() => {
                        if (self.$route.name == 'LibraryCategory') {
                            if (typeof self.id != 'undefined') {
                                self.$router.push({
                                    name: 'EditLibraryCategory',
                                    params: { id: self.id }
                                })
                                self.idCategory = self.id
                            } else {
                                self.$store.dispatch('library/addNewLibraryCategory').then((id) => {
                                    self.idCategory = id
                                    self.$router.push({ name: 'AddLibraryCategory' })
                                })
                            }
                        } else {
                            if (typeof self.id != 'undefined') {
                                self.idCategory = self.id
                            } else {
                                self.$store.dispatch('library/addNewLibraryCategory').then((id) => {
                                    self.idCategory = id
                                })
                            }
                        }
                    })
                })
            } else {
                if (self.$route.name == 'LibraryCategory') {
                    if (typeof self.id != 'undefined') {
                        self.$router.push({
                            name: 'EditLibraryCategory',
                            params: { id: self.id }
                        })
                    } else {
                        self.$store.dispatch('library/addNewLibraryCategory').then((id) => {
                            self.id = id
                            self.$router.push({ name: 'AddLibraryCategory' })
                        })
                    }
                }
            }
        },

        goBack() {
            this.$overlay.show()
            if (!this.$route.params.id) {
                this.$store.commit('library/deleteLibraryCategory', this.idCategory)
                this.$emit('back')
            } else {
                this.$store.dispatch('library/loadLibraryCategories', {}).then(() => {
                    this.$emit('back')
                })
            }
        },
        saveLibraryCategory() {
            var self = this
            self.$overlay.loading()
            var category = self.libraryCategoryRender
            self.updateRoles(self.libraryCategoryRender.roles)
            this.$store.dispatch('library/validateLibraryCategory', { id: this.idCategory, roleSwitch: this.roleSwitch, parentCategory: this.parentCategory }).then((response) => {
                if (response.status) {
                    var sendForm = self.$route.params.id ? 'editLibraryCategory' : 'addLibraryCategory'
                    self.$store.dispatch('library/' + sendForm, self.idCategory).then((response) => {
                        self.goBack()
                        self.$snackbar.open({
                            message: self.$route.params.id ? self.$t('snackbar.edit_success') + ' <b>' + category.name + '</b>' : self.$t('snackbar.add_success') + ' <b>' + category.name + '</b>',
                            customClass: 'success',
                            duration: 5000
                        })
                    })
                } else {
                    self.errors = response.errors
                    self.$overlay.hide()
                    scrollError()
                }
            })
        },

        deleteResourceFolder() {
            var self = this

            const snackbarData = {
                message: self.$t('snackbar.remove_success') + ' <b>' + self.libraryCategoryRender.name + '</b>',
                customClass: 'second',
                duration: 5000
            }

            self.$store
                .dispatch('library/deleteLibraryCategory', {
                    id: self.libraryCategoryRender.id
                })
                .then(() => {
                    self.$emit('back')
                    self.$snackbar.open(snackbarData)
                })
        },

        showDeleteResourceFolderModal() {
            var self = this
            const modalMessage = self.$t('library.form.popup_delete') + self.libraryCategoryRender.name + "'?"

            this.$popup.delete({ message: modalMessage, callSave: this.deleteResourceFolder })
        }
    },

    created() {
        this.load()
    },
    mounted() {
        if (Object.keys(this.categoriesRecursive).length > 0) {
            //get categories
            this.optionsCategories = this.parseCategories(this.categoriesRecursive)
            // sort categories
            this.optionsCategories = this.optionsCategories.sort((a, b) => a.name.localeCompare(b.name))
        }
    },
    watch: {},
    updated() {}
}
</script>

<style lang="scss">
#content.library-category-management {
    .left-container {
        padding-left: 0;
    }

    .answer {
        margin-left: 10px;
    }
}
</style>
