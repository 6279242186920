<template>
    <div id="content" class="libraryCategory">
        <template v-if="form">
            <Form :id="libraryCategoryID" @back="closeForm"></Form>
        </template>
        <template v-else>
            <div class="templates-top-bar-actions">
                <div class="left-menu-container">
                    <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                </div>
                <Button
                    :customClass="{ disabled: !hasAccess }"
                    bType="create"
                    :bCallback="addLibraryCategory"
                    bLabel="library.library_category.addButton"
                ></Button>
            </div>

            <div class="libraryCategory-container supervise-container" :class="{ 'with-trial': trialEndDate }">
                <!-- sidebar filters -->
                <div
                    class="sidebar-filters"
                    :class="{ 'empty-results': noChanges && Object.values(items).length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }"
                >
                    <FilterLibraryCategory
                        @noChanges="noChanges = true"
                        @changes="noChanges = false"
                        :isDisabled="noChanges && Object.values(items).length == 0"
                        @resetPageDatatable="top(true)"
                    ></FilterLibraryCategory>
                </div>
                <!-- TABLE -->
                <div class="data-table" :class="{ 'empty-results': noChanges && Object.values(items).length == 0 }">
                    <v-data-table
                        v-if="items"
                        v-model="selected"
                        :headers="headers"
                        fixed-header
                        :items="Object.values(items)"
                        sort-by
                        :sort-asc="true"
                        :page.sync="pagination.page"
                        :items-per-page="pagination.itemsPerPage"
                        @page-count="pageCount = $event"
                        hide-default-footer
                    >
                        <template #item.name="item">
                            <div @click="goToReport(item.item.id)">
                                <div class="name" v-html="getFinalName(item.item)"></div>
                            </div>
                        </template>
                        <template #item.roles="item">
                            <div @click="goToReport(item.item.id)">
                                <Tags :tags="getRole(item.item.roles)" :nameTag="'name'" value="id"></Tags>
                            </div>
                        </template>
                        <template #item.translations="item" v-if="organizationRender.availableLangs">
                            <div @click="goToReport(item.item.id)">
                                <template v-if="!getTranslations(item.item.translations)">
                                    <!-- {{ $t('multi-language.no_translated') }} -->
                                </template>
                                <template v-else>
                                    <Tags :tags="getTranslations(item.item.translations)" :nameTag="'name'" value="name" :backColor="'backColor'"></Tags>
                                </template>
                            </div>
                        </template>
                        <template #item.status="item">
                            <div class="status" @click="goToReport(item.item.id)">
                                <Table-statuses
                                    :text="item.item.status == '1' ? $t('sites.form.active') : item.item.status == '-2' ? $t('sites.form.inactive') : ''"
                                    :status="item.item.status == '1' ? 'active' : 'inactive'"
                                >
                                </Table-statuses>
                            </div>
                        </template>
                        <template v-slot:no-data>
                            <div class="no-data" v-if="!noChanges && itemsLoaded && items.length == 0">
                                {{ $t('supervise.checklists.table_headers.no_data') }}
                            </div>
                            <div class="no-data" v-else>
                                {{ $t('') }}
                            </div>
                        </template>
                    </v-data-table>

                    <div id="pagination" v-if="!(noChanges && items.length == 0)">
                        <div class="totalItems">
                            <span class="total">
                                <strong class="total-results">{{ Object.values(items).length }}</strong>
                                {{ $t('supervise.checklists.table_headers.results') }}
                            </span>
                        </div>
                        <v-pagination
                            v-model="pagination.page"
                            @input="top(false)"
                            color="#2c52c4"
                            :length="pageCount"
                            :total-visible="maxPageVisibles"
                        ></v-pagination>
                        <div class="rows-page">
                            <v-select :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                        </div>
                    </div>
                    <div class="andy-datatable-empty" v-if="noChanges && itemsLoaded && Object.values(items).length == 0">
                        <EmptyTable
                            @buttonAction="addLibraryCategory"
                            :buttonText="$t('library.library_category.addButton')"
                            :title="$t('empty_table.users_title')"
                            :description="$t('empty_table.library_category')"
                        ></EmptyTable>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import TableStatuses from '../components/ui/TableStatuses.vue'
import i18n from '@/i18n'
import FilterLibraryCategory from '@/components/domain/libraryCategory/filter'
import Form from '@/components/domain/libraryCategory/form'
import Tags from '@/components/ui/Tags'
import EmptyTable from '@/components/ui/EmptyTable'

export default {
    components: {
        FilterLibraryCategory,
        Form,
        Tags,
        EmptyTable,
        TableStatuses
    },
    name: 'LibraryCategory',
    data() {
        return {
            noChanges: false,
            libraryCategoryID: undefined,
            form: false,
            values: false,
            singleSelect: false,
            valueFilter: 1,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            headers: [
                {
                    text: this.$t('library.categories.name'),
                    value: 'name',
                    align: 'start',
                    sortable: true,
                    class: 'header-table'
                },
                {
                    text: this.$t('library.categories.access_roles'),
                    align: 'start',
                    sortable: true,
                    value: 'roles',
                    class: 'header-table'
                },
                {
                    text: this.$t('library.categories.status'),
                    align: 'start',
                    sortable: true,
                    value: 'status',
                    width: '150px',
                    class: 'header-table'
                }
            ],
            filtersSidebarIsHidden: false
        }
    },
    computed: {
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        pages() {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
                return 0
            }

            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        },
        items() {
            var categories = this.$store.getters['library/getLibraryCategories']()
            var listSorted = Object.values(categories).sort(function (a, b) {
                return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
            })
            return listSorted
        },
        itemsLoaded() {
            return this.$store.getters['library/getItemsCategoryLoaded']
        },
        hasAccess() {
            return this.checkPermission('/library/category/add')
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        organizationRender() {
            var org = this.$store.getters['account/getOrganization']

            if (org && org.account && org.languages) {
                var account = org.account
                // var languages = org.languages
                var availableTranslationLanguages = account.config.languages.split(',') != '' ? account.config.languages.split(',') : false

                var result = {
                    allLangs: org.languages,
                    accountLanguage: org.languages[account.lang],
                    availableLangs: availableTranslationLanguages
                }
                return result
            }
            return false
        }
    },
    methods: {
        getFinalName(item) {
            // get final name with breadcrumb searching in parents
            var finalName = ''
            let parent = item.parent_id ? item.parent_id : null
            //search recursively
            if (!parent) {
                return (finalName = "<span class='category'>" + item.name + '</span>')
            }
            while (parent) {
                var parentItem = this.items[parent]
                if (parentItem) {
                    finalName = parentItem.name + ' > ' + finalName
                    parent = parentItem.parent_id ? parentItem.parent_id : null
                } else {
                    parent = null
                }
            }
            finalName = "<span class='category'>" + item.name + '</span>' + "<span class='path'>" + finalName + '<b>' + item.name + '</b>' + '</span>'
            return finalName
        },
        top(cond) {
            if (cond && !this.$route.params.noChangePageDatatable) {
                this.pagination.page = 1
            }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        checkPermission(routePath) {
            var route = { id: 'prueba', path: routePath }
            return this.$store.getters['hasAccess'](route)
        },
        getParentCategory(id) {
            if (typeof id === 'string') {
                var category = Object.values({
                    ...this.$store.getters['library/getParentsCategoryFilters']()
                })
                if (category) {
                    for (var cat in category) {
                        var idx = category[cat]
                        if (idx) {
                            if (idx.id == id) {
                                return idx
                            }
                        }
                    }
                }
            }
            return null
        },
        goToReport(id) {
            if (this.checkPermission('/library/category/idCat')) {
                this.form = true
                this.libraryCategoryID = id
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('library.categories.no_permission'),
                    duration: 5000
                })
            }
        },

        getTranslations(oTranslations) {
            var self = this
            if (oTranslations && Object.keys(oTranslations).length > 0) {
                return Object.keys(oTranslations).map(function (key) {
                    if (self.organizationRender.availableLangs.includes(key)) {
                        return {
                            name:
                                self.organizationRender.allLangs[key].name +
                                ' <b>(' +
                                parseInt((oTranslations[key].translated / (oTranslations[key].missing + oTranslations[key].translated)) * 100) +
                                '%)</b>',
                            backColor: oTranslations[key].missing > 0 ? '#f5d9bc' : '#c2f3db'
                        }
                    }
                })
            } else return false
        },

        addLibraryCategory() {
            if (this.checkPermission('/library/category/add')) {
                this.form = true
                this.libraryCategoryID = undefined
            } else {
                var self = this

                self.$snackbar.open({
                    message: self.$t('library.categories.no_permission'),
                    duration: 5000
                })
            }
        },
        closeForm() {
            this.form = false
            this.libraryCategoryID = false
            this.$router.push({
                name: 'LibraryCategory',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        getRole(roles) {
            if (roles) {
                var result = []
                for (var role in roles) {
                    var idx = roles[role]
                    if (idx) {
                        var roleResult = false
                        roleResult = this.$store.getters['employee/getFilterRoles'](idx)
                        if (roleResult) {
                            result.push(roleResult)
                        }
                    }
                }
                return result
            }
            return false
        },
        load() {
            var self = this
            var items = this.$store.getters['library/getLibraryCategories']()
            var filters = this.$store.getters['library/getRolesCategoryFilters']()

            // HEADERS OF MULTI-LANGUAGE
            var translationHeader = {
                text: this.$t('multi-language.translations'),
                value: 'translations',
                align: 'start',
                width: '300px',
                class: 'header-table'
            }
            var hasTranslation = self.headers.filter((header) => {
                return header.value == 'translations'
            })

            if (!this.organizationRender) {
                this.$store.dispatch('account/loadListOrganization', {}).then(function () {
                    if (self.organizationRender.availableLangs.length > 0 && hasTranslation == 0) {
                        self.headers.splice(2, 0, translationHeader)
                    }
                })
            } else {
                if (self.organizationRender && self.organizationRender.availableLangs.length > 0 && hasTranslation.length == 0) {
                    self.headers.splice(2, 0, translationHeader)
                }
            }

            if (Object.keys(items).length > 0 && Object.keys(filters).length > 0) {
                self.$store.dispatch('library/loadFiltersLibraryResources', {}).then(function () {
                    if (self.$route.params.id) {
                        self.goToReport(self.$route.params.id)
                    } else if (self.$route.name == 'AddLibraryCategory') {
                        self.addLibraryCategory()
                    } else {
                        self.form = false
                        self.libraryCategoryID = false
                    }
                })
            } else {
                self.$store.dispatch('library/loadFiltersLibraryCategories', {}).then(function () {
                    self.$store.dispatch('library/loadLibraryCategories', { status: 1 }).then(function () {
                        self.$store.dispatch('library/loadLibraryCategoriesForm', { status: 1 }).then(function () {
                            self.$store.dispatch('library/loadFiltersLibraryResources', {}).then(function () {
                                if (self.$route.params.id) {
                                    self.goToReport(self.$route.params.id)
                                } else if (self.$route.name == 'AddLibraryCategory') {
                                    self.addLibraryCategory()
                                } else {
                                    self.form = false
                                    self.libraryCategoryID = false
                                }
                            })
                        })
                    })
                })
            }
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('libraryFoldersSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },
        setFiltersSidebarIsHiddenValue() {
            const storedValue = localStorage.getItem('libraryFoldersSidebarIsHiddenValue')
            this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
        }
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            // console.log(to, from)
            this.load()
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.setFiltersSidebarIsHiddenValue()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.libraryCategory {
    .libraryCategory-container {
        display: flex;
        align-items: stretch;
        height: calc(100% - 100px);

        .data-table {
            .v-data-table,
            .v-data-table table {
                .date {
                    display: flex;
                    flex-direction: column;
                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }
                .status {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }
                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;

                    &.pending {
                        color: $color-warning-800;
                    }
                }
                .ball {
                    display: inline-block;
                    margin-right: 6px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
                .name {
                    @include display-flex();
                    @include flex-direction(column);

                    .category {
                        @include font-size(md);
                        font-family: $text-bold;
                        color: $color-black;
                    }
                    .path {
                        @include font-size(11px);
                        font-family: $text-medium;
                        color: $color-black;
                        b {
                            @include font-size(xs);
                            font-family: $text-bold;
                        }
                    }
                }
                .name-normal {
                    @include font-size(sm);
                    font-family: $text-medium;
                    color: $color-neutral-600;
                }
                .ball-color {
                    display: inline-block;
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    margin-right: 3px;
                }
                .locations {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }

        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
